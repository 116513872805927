import React from "react";

import {
  ToolbarBtnFormatItalic,
  ToolbarBtnFormatBold,
  ToolbarBtnFormatCode,
  ToolbarBtnFormatUppercase,
  ToolbarBtnFormatSubscript,
  ToolbarBtnFormatSuperscript,
} from "./ToolbarBtnFormat";
import ToolbarBtnGlossario from "../InlineBlocks/Glossario/GlossarioToolbarButtons";
import { ToolbarBtnLink } from "../InlineBlocks/BlockLink";

/**
 * I bottoni di default della toolbar.
 * Essendo comuni a molti blocchi sono stati definiti qui.
 * Può essere utile anche in caso di estensione della toolbar:
 * ```
 *  useToolbar([...DEFAULT_TOOLBAR_BUTTONS, <CustomBtn />])
 * ```
 */
const DEFAULT_TOOLBAR_BUTTONS = [
  <ToolbarBtnFormatBold key="fmt-b" />,
  <ToolbarBtnFormatItalic key="fmt-i" />,
  <ToolbarBtnFormatSubscript key="fmt-sub" />,
  <ToolbarBtnFormatSuperscript key="fmt-super" />,
  <ToolbarBtnFormatCode key="fmt-c" />,
  <ToolbarBtnFormatUppercase key="fmt-uppercase" />,
  <ToolbarBtnLink key="link" />,
  <ToolbarBtnGlossario key="glossario" />,
];

export default DEFAULT_TOOLBAR_BUTTONS;
