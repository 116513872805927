import React, { PropsWithChildren, useCallback } from "react";
import { Editor } from "slate";
import { useSlate } from "slate-react";
import {
  MdFormatBold,
  MdFormatItalic,
  MdCode,
  MdFormatSize,
  MdSubscript,
  MdSuperscript,
} from "react-icons/md";

import { isMarkActive } from "../utils";
import ButtonBase from "../../components/Button";
import Icon from "../../components/Icon";
import { ReadonlyDeep } from "~/src/utils/reflection";

interface ToolbarBtnFormatGenericProps<F extends string> {
  readonly format: F;
  readonly label?: string | undefined;
  readonly onToggle: (editor: Editor, format: F, isActive: boolean) => void;
}

function ToolbarBtnFormatGeneric<F extends string>({
  format,
  children,
  label,
  onToggle,
}: PropsWithChildren<ToolbarBtnFormatGenericProps<F>>) {
  const editor = useSlate();

  const isActive = isMarkActive(editor, format);

  const onClick = useCallback(() => {
    onToggle(editor, format, isActive);
  }, [editor, format, isActive, onToggle]);

  const onMouseDown = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
    },
    [],
  );

  return (
    <ButtonBase
      square
      type="primary"
      outline={!isActive}
      ariaLabel={label}
      onMouseDown={onMouseDown}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
}

export interface Props {
  format: string;
  label?: string | undefined;
}

function onToggleBtnCommon(
  editor: Editor,
  format: string,
  isActive: boolean,
): void {
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

function ToolbarBtnFormat({
  format,
  children,
  label,
}: ReadonlyDeep<PropsWithChildren<Props>>) {
  return (
    <ToolbarBtnFormatGeneric
      format={format}
      label={label}
      onToggle={onToggleBtnCommon}
    >
      {children}
    </ToolbarBtnFormatGeneric>
  );
}

export const ToolbarBtnFormatItalic = () => (
  <ToolbarBtnFormat format="italic" label="italico">
    <Icon label="italico" icon={MdFormatItalic} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatBold = () => (
  <ToolbarBtnFormat format="bold" label="grassetto">
    <Icon label="grassetto" icon={MdFormatBold} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatCode = () => (
  <ToolbarBtnFormat format="code" label="codice">
    <Icon label="codice" icon={MdCode} size="30px" />
  </ToolbarBtnFormat>
);

export const ToolbarBtnFormatUppercase = () => (
  <ToolbarBtnFormat format="uppercase" label="maiuscolo">
    <Icon label="maiuscolo" icon={MdFormatSize} size="30px" />
  </ToolbarBtnFormat>
);

export function _onSuperscriptSubscriptToggle(
  editor: Editor,
  format: "superscript" | "subscript",
  isActive: boolean,
): void {
  if (isActive) {
    Editor.removeMark(editor, format);
    return;
  }

  let otherFormat: "superscript" | "subscript";
  switch (format) {
    case "superscript":
      otherFormat = "subscript";
      break;
    case "subscript":
      otherFormat = "superscript";
      break;
  }

  Editor.removeMark(editor, otherFormat);
  Editor.addMark(editor, format, true);
}

export const ToolbarBtnFormatSuperscript = () => (
  <ToolbarBtnFormatGeneric
    format="superscript"
    label="apice"
    onToggle={_onSuperscriptSubscriptToggle}
  >
    <Icon label="apice" icon={MdSuperscript} size="30px" />
  </ToolbarBtnFormatGeneric>
);

export const ToolbarBtnFormatSubscript = () => (
  <ToolbarBtnFormatGeneric
    format="subscript"
    label="pedice"
    onToggle={_onSuperscriptSubscriptToggle}
  >
    <Icon label="pedice" icon={MdSubscript} size="30px" />
  </ToolbarBtnFormatGeneric>
);

export default ToolbarBtnFormat;
